import React, { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { SimpleBackdrop } from "../Components/Loading";
import { getOrders } from "../ContextAPI/Action";
import { useAuthState } from "../ContextAPI/Context";
import { auth } from "../firebase";
import ProductPreview from "./ProductPreview";

const MyOrder = () => {
  const location = useLocation();
  const [{ loading, orders }, dispatch] = useAuthState();

  useEffect(() => {
    getOrders(dispatch);
  }, []);
  // console.log(typeof orders[0].createdAt.toDate().toString())
  if (!auth.currentUser)
    return <Navigate to="/login" replace state={{ from: location }} />;

  return (
    <>
      {loading && <SimpleBackdrop />}
      {orders.length === 0 ? (
        <div className="py-4">
          <p className="text-base my-64 font-medium text-center leading-6 text-gray-600">
            No Orders Available!
          </p>
        </div>
      ) : (
        orders.map((order, index) => (
          <div
            className="py-14 px-4 md:px-6 2xl:px-20 2xl:container 2xl:mx-auto"
            key={order.id}
          >
            <div className="flex justify-start item-start space-y-2 flex-col ">
              <h1 className="text-3xl lg:text-4xl font-semibold leading-7 lg:leading-9  text-gray-800">
                Order #{index + 1}
              </h1>
              <p className="text-base font-medium leading-6 text-gray-600">{order.createdAt.toDate().toString()}</p>
            </div>
            <div className="mt-10 flex flex-col xl:flex-row jusitfy-center items-stretch  w-full xl:space-x-8 space-y-4 md:space-y-6 xl:space-y-0">
              <div className="flex flex-col justify-start items-start w-full space-y-4 shadow-lg md:space-y-6 xl:space-y-8">
                <div className="flex flex-col justify-start items-start  px-4 py-4 md:py-6 md:p-6 xl:p-8 w-full">
                  <p className="text-lg md:text-xl font-semibold leading-6 xl:leading-5 text-gray-800">
                    Customer’s Cart
                  </p>
                  {order.products.map((product) => (
                    <div
                      className="mt-4 flex flex-col md:flex-row justify-start items-start md:items-center md:space-x-6 xl:space-x-8 w-full "
                      key={product.id}
                    >
                      <div className="pb-4 md:pb-8 w-full md:w-40">
                        <img
                          className="w-full hidden md:block"
                          src={product.img}
                          alt={product.name}
                        />
                        <img
                          className="w-full md:hidden"
                          src={product.img}
                          alt={product.name}
                        />
                      </div>
                      <div className="border-b border-gray-200 md:flex-row flex-col flex justify-between items-start w-full  pb-8 space-y-4 md:space-y-0">
                        <div className="w-full flex flex-col justify-start items-start space-y-8">
                          <h3 className="text-xg xl:text-xl font-semibold leading-6 text-gray-800">
                            {product.name}
                          </h3>
                          <div className="flex justify-start items-start flex-col space-y-2">
                            <p className="text-sm leading-none text-gray-800">
                              {product.category}
                            </p>
                          </div>
                        </div>
                        <div className="flex justify-between space-x-8 items-start w-full">
                          <p className="text-base xl:text-lg leading-6">
                            NRs {product.price}
                          </p>
                          <p className="text-base xl:text-lg leading-6 text-gray-800">
                            x{product.qty}
                          </p>
                          <p className="text-base xl:text-lg font-semibold leading-6 text-gray-800">
                            NRs{" "}
                            {parseInt(product.price) * parseInt(product.qty)}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className=" w-full xl:w-96 flex justify-between items-center shadow-lg md:items-start px-2 py-2 xl:p-8 flex-col ">
                <div className="flex flex-col p-2 w-full  space-y-6   ">
                  <h3 className="text-xl font-semibold leading-5 text-gray-800">
                    Summary
                  </h3>
                  <div className="flex justify-center items-center w-full space-y-4 flex-col border-gray-200 border-b pb-4">
                    <div className="flex justify-between  w-full">
                      <p className="text-base leading-4 text-gray-800">
                        Subtotal
                      </p>
                      <p className="text-base leading-4 text-gray-600">
                        {order.subTotal}
                      </p>
                    </div>
                    {/* <div className="flex justify-between items-center w-full">
                                    <p className="text-base leading-4 text-gray-800">Shipping</p>
                                    <p className="text-base leading-4 text-gray-600">$8.00</p>
                                </div> */}
                  </div>
                  <div className="flex justify-between items-center w-full">
                    <p className="text-base font-semibold leading-4 text-gray-800">
                      Total
                    </p>
                    <p className="text-base font-semibold leading-4 text-gray-600">
                      {order.subTotal}
                    </p>
                  </div>
                </div>
                <div className="flex flex-col p-2 w-full  space-y-6">
                  <h3 className="text-xl font-semibold leading-5 text-gray-800">
                    Shipping Status
                  </h3>
                  <div className="flex justify-between items-start w-full">
                    <div className="flex justify-center items-center space-x-4">
                      <div className="w-8 h-8">
                        <img
                          className="w-full h-full"
                          alt="logo"
                          src="https://i.ibb.co/L8KSdNQ/image-3.png"
                        />
                      </div>
                      <div className="flex flex-col justify-start items-center">
                        <p className="text-lg leading-6 font-semibold text-gray-800">
                          {order.status}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="w-full flex justify-center items-center">
                    <button className="hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 py-5 w-96 md:w-full bg-gray-800 text-base font-medium leading-4 text-white">
                      Track Order
                    </button>
                  </div>
                </div>
                {/* </div> */}
              </div>
            </div>
          </div>
        ))
      )}
    </>
  );
};

export default MyOrder;
