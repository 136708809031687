import React from 'react'
import { Outlet } from 'react-router-dom'
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import Nav from '../Components/Nav';

function ClientLayout() {
    return (
        <div>
            <Nav />
                <Outlet />
            <Footer />
        </div>
    )
}

export default ClientLayout