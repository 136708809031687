import { collection, getDocs, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Breadcrumbs from "../Components/Breadcrumbs";
import { CardSkeleton, SimpleBackdrop } from "../Components/Loading";
import db from "../firebase";


export default function CategoryList() {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    let categoriesRef
    const getCategoriesData = async () => {
      if (slug === 'All'){
        categoriesRef = collection(db, 'Categories')
      }else{
        categoriesRef = query(
        collection(db, "Categories"),
        where("categoryType", "==", slug)
      );
      }
      
      setLoading(true)
      try {
        const categoriesData = await getDocs(categoriesRef);
        const categoriesFetched = categoriesData.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setCategories(categoriesFetched);
        setLoading(false)
      } catch (err) {
        navigate("/");
        setLoading(false)
      }
    };
    getCategoriesData();
  }, [slug]);

  if (categories.length === 0 && loading) return (
    <div className="bg-white">
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="max-w-2xl mx-auto py-16 mt-2 lg:py-20 lg:max-w-none">
      <Breadcrumbs child={slug} />
        <div className="space-y-4 lg:space-y-0 lg:grid lg:grid-cols-4 lg:gap-x-4 mt-4">
          <CardSkeleton />
          <CardSkeleton />
          <CardSkeleton />
          <CardSkeleton />
        </div>
      </div>
    </div>
    </div>
  );

  return (
    <div className="bg-white">
      <div className="max-w-2xl mx-auto py-20 mt-1 px-4 lg:py-24 lg:px-8 lg:max-w-7xl">
        <Breadcrumbs child={slug === 'All' ? 'Categories' : slug} />

        <div className="mt-3 lg:mt-4 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
          {categories.length > 0 ?
            categories.slice(0, 5).map((category) => (
              <div className="group relative" key={category.id}>
                <div className="relative w-full h-80 bg-white rounded-sm overflow-hidden group-hover:opacity-75 sm:aspect-w-2 sm:aspect-h-1 sm:h-64 lg:aspect-w-1 lg:aspect-h-1">
                  <img
                    src={category.categoryThumbnail}
                    alt={category.categoryName}
                    className="w-full h-full object-center object-cover"
                  />
                </div>
                <h3 className="mt-6 text-sm text-gray-500">
                <Link to={`/products/${category.categoryName}/${category.id}`}>
                    <span className="absolute inset-0" />
                    {category.categoryType}
                  </Link>
                </h3>
                <p className="text-base font-semibold text-gray-900">
                  {category.categoryName}
                </p>
              </div>
            )):
            <div className="w-full h-96 lg:h-80 m-0 lg:m-1">
            <div className="border-l-4 border-indigo-500">
              <p className="text-gray-400 text-lg p-2">No Categories found !</p>
            </div>
          
          </div>
          }
        </div>
      </div>
    </div>
  );
}
