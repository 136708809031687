import React from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import { auth } from '../firebase';
import Nav from '../Components/Nav';

function ClientPrivateLayout() {
    const location = useLocation()
    return (
        <div>
            <Nav />
                {auth.currentUser ? <Outlet /> : <Navigate to="/login" replace state={{from: location}}/>}
            <Footer />
        </div>
    )
}

export default ClientPrivateLayout