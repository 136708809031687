import React from "react";

const Stepper = () => {
  return (
    <div className="grid grid-cols-4 gap-4 w-full m-auto py-8 px-4 my-4 shadow-lg bg-white">
      <div className="border-t-4 border-primary pt-4">
        <p className="text-background2 font-normal">Add to cart</p>
      </div>
      <div className="border-t-4 border-primary pt-4">
        <p className="text-background2 font-normal">Checkout</p>
      </div>
      <div className="border-t-4 border-gray-400 pt-4">
        <p className="text-gray-600 font-normal">Payment</p>
      </div>
      <div className="border-t-4 border-gray-400 pt-4">
        <p className="text-gray-600 font-normal">Order</p>
      </div>
    </div>
  );
};

export default Stepper;
