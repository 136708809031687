import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Chart from "./Chart";
import Deposits from "./Deposits";
import Orders from "./Orders";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import { styled } from '@mui/material/styles';
import { getCategories } from "../ContextAPI/Action";
import { useAuthState } from "../ContextAPI/Context";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://tekjoshi.com.np/">
        Accessories Nepal
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}


const Main = () => {
  const [{loading}, dispatch] = useAuthState()
  useEffect(() => {
    getCategories(dispatch)
  },[])
  return (
    <Box sx={{ flexGrow: 1, marginTop: 10, padding: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Chart />
        </Grid>
        <Grid item xs={12}>
          <Orders />
        </Grid>
      </Grid>
      <Box pt={4}>
        <Copyright />
      </Box>
    </Box>
  );
};

export default Main;
