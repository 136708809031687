import { onAuthStateChanged } from 'firebase/auth';
import React, { useEffect } from 'react'
import { useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import Dashboard from '../AdminComponents/Dashboard'
import { auth } from '../firebase';

function AdminLayout() {
  const [user, setUser] = useState('');
  const navigate = useNavigate()
  
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
          setUser(currentUser);
        });
        return () => {
          unsubscribe();
        };
      }, []);
      
      if (!user || (user && user.uid !== "aXvqQBZzd4Ugn0sbrmbKJDpFGau2")){
        navigate("/admin-login")
      }
    return (
        <div>
            <Dashboard>
                <Outlet />
            </Dashboard>
        </div>
    )
}

export default AdminLayout