import React, { useEffect, useState } from "react";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { Button, Stack, Typography } from "@mui/material";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useAuthState } from "../ContextAPI/Context";
import Title from "./Title";

const media = {
  maxHeight: 245,
  maxWidth: 245,
  margin: "5px 0",
};

export const ImageUpload = ({
  prevProductThumbnail_1,
  prevProductThumbnail_2,
  prevProductThumbnail_3,
}) => {
  /** @type {any} */
  const metadata = {
    contentType: "image/jpeg",
  };
  const [thumbnail_1, setThumbnail_1] = useState("");
  const [thumbnail_2, setThumbnail_2] = useState("");
  const [thumbnail_3, setThumbnail_3] = useState("");

  const [progress1, setProgress1] = useState(0);
  const [progress2, setProgress2] = useState(0);
  const [progress3, setProgress3] = useState(0);

  const [{ thumbnail1URL, thumbnail2URL, thumbnail3URL }, dispatch] = useAuthState();

  useEffect(() => {
    dispatch({
      type: "GET_THUMBNAIL1URL",
      thumbnail1URL: prevProductThumbnail_1,
    });
    dispatch({
      type: "GET_THUMBNAIL2URL",
      thumbnail2URL: prevProductThumbnail_2,
    });
    dispatch({
      type: "GET_THUMBNAIL3URL",
      thumbnail3URL: prevProductThumbnail_3,
    });
  }, []);
  const storage = getStorage();

  const handleImg1Change = (e) => {
    setProgress1(0)
    setThumbnail_1(e.target.files[0]);
  };
  const handleImg2Change = (e) => {
    setProgress2(0)
    setThumbnail_2(e.target.files[0]);
  };
  const handleImg3Change = (e) => {
    setProgress3(0)
    setThumbnail_3(e.target.files[0]);
  };

  const handleUpload = (thumbnail, file) => {
    console.log(thumbnail, file)
    const storageRef = ref(storage, "products/" + file.name);
    const uploadTask = uploadBytesResumable(storageRef, file, metadata);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        var updateStatus = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        if (thumbnail === 1) setProgress1(updateStatus)
        else if (thumbnail === 2) setProgress2(updateStatus)
        else setProgress3(updateStatus)
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
          default:
            console.log("Upload Complete!");
        }
      },
      (error) => {
        switch (error.code) {
          case "storage/unauthorized":
            break;
          case "storage/canceled":
            break;
          case "storage/unknown":
            break;
          default:
            console.log("Unknown Error Occured!");
        }
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          if (thumbnail === 1)
            dispatch({
              type: "GET_THUMBNAIL1URL",
              thumbnail1URL: downloadURL,
            });
          else if (thumbnail === 2)
            dispatch({
              type: "GET_THUMBNAIL2URL",
              thumbnail2URL: downloadURL,
            });
          else
            dispatch({
              type: "GET_THUMBNAIL3URL",
              thumbnail3URL: downloadURL,
            });
        });
      }
    );
  };

  return (
    <div>
    <div className="flex flex-col lg:flex-row">
      {/* Thumbnail 1 */}
      <div className="p-2">
        <Title style={{ marginTop: "15px" }}>Main Image</Title>
        {thumbnail_1 && (
          <img
            style={ media }
            src={URL.createObjectURL(thumbnail_1)}
            alt="product thumbnail 1"
          />
        )}
        {!thumbnail_1 && prevProductThumbnail_1 && (
          <img
            style={ media }
            src={prevProductThumbnail_1}
            alt="product thumbnail 1"
          />
        )}
        <input
          accept="image/*"
          style={{ display: "none" }}
          id="contained-button-file1"
          type="file"
          onChange={(e) =>handleImg1Change(e)}
        />
        <Stack direction="column" spacing={1} className="mt-1">
          <label htmlFor="contained-button-file1">
            <Button
              variant="contained"
              sx={{ width: 245 }}
              startIcon={<PhotoCamera />}
              component="span"
            >
              Select Image
            </Button>
          </label>
          <label>
            <Button
              style={{ width: 245 }}
              onClick={() => handleUpload(1, thumbnail_1)}
              disabled={
                !thumbnail_1 ||
                Math.round(progress1) < 0 ||
                Math.round(progress1) === 100
              }
              variant="contained"
              color="success"
              startIcon={<CloudUploadIcon />}
            >
              Upload
            </Button>
          </label>
        </Stack>
        {thumbnail_1 && (
          <Typography variant="body2" sx={{ marginTop: 1 }} gutterBottom>
            {thumbnail_1.name}
          </Typography>
        )}
        {progress1 !== 0 && (
          <Typography variant="body2">{Math.round(progress1)}%</Typography>
        )}
      </div>

      {/* Thumbnail 2 */}
      <div className="p-2 ">
        <Title style={{ marginTop: "15px" }}>Image 2</Title>
        {thumbnail_2 && (
          <img
            style={ media }
            src={URL.createObjectURL(thumbnail_2)}
            alt="product thumbnail 2"
          />
        )}
        {!thumbnail_2 && prevProductThumbnail_2 && (
          <img
            style={ media }
            src={prevProductThumbnail_2}
            alt="product thumbnail 2"
          />
        )}
        <input
          accept="image/*"
          style={{ display: "none" }}
          id="contained-button-file2"
          type="file"
          onChange={(e) => handleImg2Change(e)}
        />
        <Stack direction="column" spacing={1} className="mt-1">
          <label htmlFor="contained-button-file2">
            <Button
              variant="contained"
              sx={{ width: 245 }}
              startIcon={<PhotoCamera />}
              component="span"
            >
              Select Image
            </Button>
          </label>
          <label>
            <Button
              style={{ width: 245 }}
              onClick={() => handleUpload(2, thumbnail_2)}
              disabled={
                !thumbnail_2 ||
                Math.round(progress2) < 0 ||
                Math.round(progress2) === 100
              }
              variant="contained"
              color="success"
              startIcon={<CloudUploadIcon />}
            >
              Upload
            </Button>
          </label>
        </Stack>
        {thumbnail_2 && (
          <Typography variant="body2" sx={{ marginTop: 1 }} gutterBottom>
            {thumbnail_2.name}
          </Typography>
        )}
        {progress2 !== 0 && (
          <Typography variant="body2">{Math.round(progress2)}%</Typography>
        )}
      </div>
</div>
      {/* Thumbnail 3 */}
      <div>
        <Title style={{ marginTop: "15px" }}>Image 3</Title>
        {thumbnail_3 && (
          <img
            style={ media }
            src={URL.createObjectURL(thumbnail_3)}
            alt="product thumbnail 3"
          />
        )}
        {!thumbnail_3 && prevProductThumbnail_3 && (
          <img
            style={ media }
            src={prevProductThumbnail_3}
            alt="product thumbnail 3"
          />
        )}
        <input
          accept="image/*"
          style={{ display: "none" }}
          id="contained-button-file3"
          type="file"
          onChange={(e) => handleImg3Change(e)}
        />
        <Stack direction="column" spacing={1} className="mt-1">
          <label htmlFor="contained-button-file3">
            <Button
              variant="contained"
              sx={{ width: 245 }}
              startIcon={<PhotoCamera />}
              component="span"
            >
              Select Image
            </Button>
          </label>
          <label>
            <Button
              style={{ width: 245 }}
              onClick={() => handleUpload(3, thumbnail_3)}
              disabled={
                !thumbnail_3 ||
                Math.round(progress3) < 0 ||
                Math.round(progress3) === 100
              }
              variant="contained"
              color="success"
              startIcon={<CloudUploadIcon />}
            >
              Upload
            </Button>
          </label>
        </Stack>
        {thumbnail_3 && (
          <Typography variant="body2" sx={{ marginTop: 1 }} gutterBottom>
            {thumbnail_3.name}
          </Typography>
        )}
        {progress3 !== 0 && (
          <Typography variant="body2">{Math.round(progress3)}%</Typography>
        )}
      </div>
    </div>
  );
};
