import React from 'react'
import BaseRouter from './routes';

function App() {

  return (
    <div className="App">
      <BaseRouter />
    </div>
  );
}

export default App;