import { createUserWithEmailAndPassword, getAuth, updateProfile } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import Alertbar from "../Components/Alertbar";
import {SimpleBackdrop } from "../Components/Loading";
import { userLogout } from "../ContextAPI/Action";
import { useAuthState } from "../ContextAPI/Context";

export default function SignUn() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isRegistered, setIsRegistered] = useState(false);
  const auth = getAuth();

  const [{loading}, dispatch] = useAuthState()

  const handleSubmit = (e) => {
    e.preventDefault();
    setError('')
    if(email && password && name)
    dispatch({
      type: "START_LOADING",
    })
    createUserWithEmailAndPassword(auth, email, password)
    .then(() => {
      return updateProfile(auth.currentUser, {
        displayName: name,
      })
    }).then(()=>{
      dispatch({
        type: "REGISTRARTION_SUCCESS",
      })
      setIsRegistered(true)
    })
    .catch((error) => {
      dispatch({
        type: "ACTION_FAILED"
      })
      setError(error.code)
    });
  }

  useEffect(() => {
    userLogout(dispatch)
  },[])

  if(isRegistered) return <Navigate to="/login" />
  return (
    <>
    {loading ? <SimpleBackdrop /> : null}
    {error ? <Alertbar status={"error"} text={error} /> : null}
      <main className="bg-background3">
        <section className="w-full h-full py-2">
          <div className="container mx-auto px-4 h-full">
            <div className="flex content-center items-center justify-center h-full">
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative flex flex-col min-w-0 break-words w-full shadow-lg rounded-lg bg-white border-0">
                  <div className="rounded-t mb-0 px-6 pt-3 pb-2">
                    <div className="text-center mb-4">
                      <h6 className="text-gray-700 text-sm font-bold">
                        Login with
                      </h6>
                    </div>
                    <div className="btn-wrapper text-center">
                      <button
                        className="bg-white active:bg-gray-100 text-gray-800 font-normal px-4 py-2 rounded outline-none focus:outline-none mr-2 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs"
                        type="button"
                        style={{ transition: "all .15s ease" }}
                      >
                        <img
                          src="https://img.icons8.com/color/48/000000/google-logo.png"
                          className="w-5 mr-1"
                          alt="google-logo"
                        />
                        Github
                      </button>
                      <button
                        className="bg-white active:bg-gray-100 text-gray-800 font-normal px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs"
                        type="button"
                        style={{ transition: "all .15s ease" }}
                      >
                        <img
                          src="https://img.icons8.com/color/48/000000/facebook-new.png"
                          alt="facebook-logo"
                          className="w-5 mr-1"
                        />
                        Facebook
                      </button>
                    </div>
                    <hr className="mt-6 border-b-1 border-gray-400" />
                  </div>
                  <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                    <div className="text-gray-700 text-center mb-3 font-bold">
                      <small>Or sign up with credentials</small>
                    </div>
                    <form onSubmit={handleSubmit}>
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-gray-700 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Full Name
                        </label>
                        <input
                          type="text"
                          required
                          className="border-0 px-3 py-3 placeholder-gray-500 text-background2 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          placeholder="Full Name"
                          style={{ transition: "all .15s ease" }}
                        />
                      </div>
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-gray-700 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Email
                        </label>
                        <input
                          type="email"
                          required
                          className="border-0 px-3 py-3 placeholder-gray-500 text-background2 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder="Email Address"
                          style={{ transition: "all .15s ease" }}
                        />
                      </div>

                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-gray-700 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Password
                        </label>
                        <input
                          type="password"
                          required
                          className="border-0 px-3 py-3 placeholder-gray-500 text-background2 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          placeholder="Password"
                          style={{ transition: "all .15s ease" }}
                        />
                      </div>
                      <div className="text-center mt-6">
                        <button
                          className="bg-primary text-gray-700 hover:bg-secondary text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full"
                          type="submit"
                          style={{ transition: "all .15s ease" }}
                        >
                          Sign Up
                        </button>
                        <div className="mt-6">
                          <div className="text-center">
                            <span className="font-light text-gray-800 text-sm">
                              Already have an account ?{" "}
                            </span>
                            <Link
                              to="/login"
                              style={{ transition: "all .15s ease" }}
                              className="font-semibold text-gray-700 hover:text-secondary text-sm"
                            >
                              Login
                            </Link>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
