import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Banners from "./AdminComponents/Banners";
import Categories from "./AdminComponents/Categories";
import Login from "./AdminComponents/Login";
import Main from "./AdminComponents/Main";
import Requests from "./AdminComponents/Requests";
import Products from "./AdminComponents/Products";
import Home from "./ClientComponents/Home";
import ProductList from "./ClientComponents/ProductList";
import AdminLayout from "./hoc/AdminLayout";
import ClientLayout from "./hoc/ClientLayout";
import ClientPrivateLayout from "./hoc/ClientPrivateLayout";
import ProductPreview from "./ClientComponents/ProductPreview";
import Page404 from "./Components/Page404";
import CategoryList from "./ClientComponents/CategoryList";
import SignIn from "./Pages/SignIn";
import SignUp from "./Pages/SignUp";
import ForgotPass from "./Pages/ForgotPass";
import Checkout from "./ClientComponents/Checkout";
import MyOrder from "./ClientComponents/MyOrder";

const BaseRouter = () => (
  <Router>
    <Routes>
      <Route path="/" element={<ClientLayout />}>
        <Route index element={<Home />} />
        <Route path="products/:categoryName/:slug" element={<ProductList />} />
        <Route path="products/:slug" element={<ProductList />} />
        <Route path="product/:productName/:slug" element={<ProductPreview />} />
        <Route path="categories/:slug" element={<CategoryList />} />
        <Route path="login/" element={<SignIn />} />
        <Route path="register/" element={<SignUp />} />
        <Route path="forgot/password" element={<ForgotPass />} />
      </Route>
      <Route path="/user" element={<ClientPrivateLayout />}>
        <Route path="checkout" element={<Checkout />} />
        <Route path="my-orders" element={<MyOrder />} />
      </Route>

      <Route path="/admin-login">
        <Route index element={<Login />} />
      </Route>
      <Route path="/admin" element={<AdminLayout />}>
        <Route index element={<Main />} />

        <Route path="/admin/products">
          <Route index element={<Products />} />
        </Route>
        <Route path="/admin/categories">
          <Route index element={<Categories />} />
        </Route>
        <Route path="/admin/banners">
          <Route index element={<Banners />} />
        </Route>
        <Route path="/admin/requests">
          <Route index element={<Requests />} />
        </Route>
      </Route>
      <Route path="*" element={<Page404 />} />
    </Routes>
  </Router>
);

export default BaseRouter;
