import React, { lazy, Suspense } from 'react'
import { SimpleBackdrop } from '../Components/Loading.js';
const HeroSection = lazy(() => import("./HeroSection.js"));
const PopularCategories = lazy(() => import("./PopularCategories.js"));
const FeaturedProducts = lazy(() => import("./FeaturedProducts.js"));
const Procedure = lazy(() => import("./Procedure.js"));
const Statistic = lazy(() => import("./Statistic.js"));
const ProductRequest = lazy(() => import("./ProductRequest.js"));

const Home = () => {
    return (
        <Suspense fallback={<SimpleBackdrop />}>
            <HeroSection />
            <PopularCategories />
            <FeaturedProducts />
            <Procedure />
            <Statistic />
            <ProductRequest />
        </Suspense>
    )
}

export default Home
