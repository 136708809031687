import React, { useEffect, useState } from "react";
import localForage from "localforage";
import {auth} from "../firebase";
import PaymentInfo from "../Components/PaymentInfo";
import { Link, Navigate, useLocation } from "react-router-dom";
import { requestOrder, userLogout } from "../ContextAPI/Action";
import { useAuthState } from "../ContextAPI/Context";
import Stepper from "../Components/Stepper";
import { SimpleBackdrop } from "../Components/Loading";

const Checkout = () => {
  const [subTotal, setSubTotal] = useState(0);
  const [productsInCart, setProductsInCart] = useState([]);
  const [{loading, orderPlaced}, dispatch] = useAuthState()
    // const location = useLocation()

  useEffect(() => {
    const getCartData = () =>
      localForage
        .getItem("ShoppingCart")
        .then((value) => {
          setProductsInCart(value || []);
          if (productsInCart.length !== 0) {
            setSubTotal(
              productsInCart.reduce(
                (a, b) => a + parseInt(b.price) * parseInt(b.qty),
                0
              )
            );
          } else {
            setSubTotal(0);
          }
        })
        .catch(() => {
          setProductsInCart([]);
          setSubTotal(0);
        });
    getCartData();
  }, [productsInCart]);

  const handleOrderRequest = () =>{
    const products = productsInCart.map((product)=>{
      return {id: product.id, name: product.name, category: product.category, qty: product.qty, price: product.price, img: product.img}
    })
    requestOrder(dispatch, products, subTotal)
  }

  if (orderPlaced) return ( <Navigate to='/' />)
  return (
    <>
    {loading && <SimpleBackdrop />}
    <div className="flex items-center justify-between w-full p-2 lg:p-8 bg-background3">

      <div className="flex flex-col lg:flex-row p-2 w-full items-start mr-0 lg:mr-2lg:items-center rounded ">

        <div className="w-full lg:w-2/3 bg-white shadow-md">
        <h4 className="text-2xl lg:text-3xl bg-white text-gray-900 rounded shadow py-4 text-center font-medium leading-tight mt-2 mb-2">Checkout</h4>

          <div className="p-6 flex items-center sm:flex-row flex-wrap">
            <div className="h-8 w-8 lg:mb-0 mr-4">
              <img
                src={`https://ui-avatars.com/api/?name=${auth.currentUser?.displayName}`}
                alt="avatar"
                className="h-full w-full rounded-full overflow-hidden shadow"
              />
            </div>
            <h5 className="text-lg text-gray-800 font-normal">
              {auth.currentUser?.displayName}
            </h5>

          </div>
          <div className="px-6 flex items-center sm:flex-row flex-wrap">
          <div className="w-8 lg:mb-0 mr-4">
          <h4 className="text-lg ml-2">Email:</h4>
            <h5 className="text-base text-gray-800 ml-2 mb-2 font-normal">
              {auth.currentUser?.email}
            </h5>
            </div>
            </div>
            <Stepper />
                {/* <Navigate onClick={()=>userLogout(dispatch)} replace state={{from: location}} to="/login" className="text-primary hover:text-secondary"> Change Email </Navigate> */}
            
          <PaymentInfo />
          <div className="p-2 my-4">
            <p className="float-right text-primary font-bold text-lg mb-2">NRs {subTotal}</p>
          </div>
          {productsInCart.length !== 0 && <button type="button" onClick={handleOrderRequest} class="mb-4 w-full inline-block px-6 py-2.5 bg-primary text-white font-bold rounded shadow-md hover:bg-secondary hover:shadow-lg  transition duration-150 ease-in-out">Process Order</button>}
        </div>
        <div className="w-full lg:w-1/3 p-2 shadow ml-0 lg:ml-2 text-gray-900 bg-white rounded">
        <h4 className="text-2xl font-medium leading-tight mt-2 mb-2 ml-4">Cart Summary</h4>
        {productsInCart.length === 0 ? 
        
        <div className="mt-6 text-sm text-center text-gray-900">
          <p className="text-center font-medium mb-2 text-sm ">No products in cart!</p>
                        <p>
                          <Link 
                          to="/"
                            className="text-primary font-medium hover:text-secondary"
                          >
                            Continue Shopping
                            <span aria-hidden="true"> &rarr;</span>
                          </Link>
                        </p>
                      </div>
                       :
          <div className="flex flex-col">
            <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                <div className="overflow-hidden">
                  <table className="min-w-full">
                    <thead className="bg-white text-gray-900 border-b">
                      <tr>
                        <th
                          scope="col"
                          className="text-sm font-medium px-6 py-4 text-left"
                        ></th>
                        <th
                          scope="col"
                          className="text-sm font-medium px-6 py-4 text-left"
                        >
                          Price
                        </th>
                        <th
                          scope="col"
                          className="text-sm font-medium px-6 py-4 text-left"
                        >
                          Qty
                        </th>
                        <th
                          scope="col"
                          className="text-sm font-medium px-6 py-4 text-left"
                        >
                          Total
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {productsInCart.map((product) => (
                        <tr className="bg-white text-gray-900 border-b transition duration-300 ease-in-out hover:bg-gray-200" key={product.id}>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <p className="text-base font-medium">{product.name}</p>
                            <p className="text-sm font-normal">{product.category}</p>

                          </td>
                          <td className="text-sm font-normal px-6 py-4 whitespace-nowrap">
                            NRs {product.price}
                          </td>
                          <td className="text-sm font-normal px-6 py-4 whitespace-nowrap">
                            {product.qty}
                          </td>
                          <td className="text-sm font-normal px-6 py-4 whitespace-nowrap">
                            NRs{" "}
                            {parseInt(product.price) * parseInt(product.qty)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          }
        </div>
      </div>
    </div>
    </>
  );
};

export default Checkout;
