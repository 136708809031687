import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import DeleteItem from "./DeleteItem";
import { deleteProduct } from "../ContextAPI/Action";
import CreateProduct from "./CreateProduct";
import { doc, getDoc } from "firebase/firestore";
import db from "../firebase";

const useStyles = {
  root: {
    maxWidth: 355,
  },
  media: {
    objectFit: "scale-down",
    height: 200,
  },
}

export default function ProductCard({ product, dispatch }) {
  const [categoryName, setCategoryName] = useState('')

  useEffect(() => {
    const fetchData = async() => {
        const docRef = doc(db, "Categories", product.productCategory.id);
  const docSnap = await getDoc(docRef);
  setCategoryName(docSnap.data().categoryName)
    }
    fetchData()
  },[])



  return (
    <Card sx={useStyles.root}>
      <CardActionArea>
        <CardMedia
          sx={useStyles.media}
          component="img"
          image={product.productThumbnail_1}
          title={product.productName}
        />
        <CardContent>
          <Typography gutterBottom variant="body1" component="h2">
            {product.productName}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            Price: NRs {product.productPrice}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            Category: {categoryName}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <CreateProduct
          edit={true}
          id={product.id}
          prevProductPrice={product.productPrice}
          prevProductTag={product.productTag}
          prevProductCategory={product.productCategory.id}
          prevProductName={product.productName}
          prevProductDescription={product.productDescription}
          prevProductThumbnail_1={product.productThumbnail_1}
          prevProductThumbnail_2={product.productThumbnail_2}
          prevProductThumbnail_3={product.productThumbnail_3}
        />
        <DeleteItem
          deleteFun={() => deleteProduct(dispatch, product.id)}
          item={product.productName}
          icon={false}
        />
      </CardActions>
    </Card>
  );
}
