import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  limit,
  orderBy,
  query,
  Timestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import db, { app } from "../firebase";
import { getAuth, signOut, signInWithEmailAndPassword } from "firebase/auth";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import localForage from "localforage";

const auth = getAuth();

export const adminLogin = async (dispatch, email, password) => {
  dispatch({
    type: "START_LOADING",
  });
  signInWithEmailAndPassword(auth, email, password)
  .then((userCredential) => {
    if(userCredential.user.uid !== "aXvqQBZzd4Ugn0sbrmbKJDpFGau2"){
      userLogout(dispatch)
      dispatch({
        type: "ACTION_FAILED"
      });
    }else{
      dispatch({
      type: "LOGIN_SUCCESS",
    });
    }
    
  })
  .catch((error) => {
    dispatch({
      type: "ACTION_FAILED"
    });
  });
}


export const userLogout = async(dispatch) => {
  signOut(auth).then(() => {
    dispatch({
      type: "LOGOUT_SUCCESS",
    });
  console.log(auth.currentUser.uid)
  }).catch((error) => {
    // An error happened.
  });
}

export const getCategories = async (dispatch) => {
  const categoryCollection = collection(db, "Categories");
  var categoriesFetched = [];
  dispatch({
    type: "START_LOADING",
  });
  try {
    const data = await getDocs(categoryCollection);
    categoriesFetched = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    dispatch({
      type: "GET_CATEGORIES",
      categories: categoriesFetched,
    });
  } catch (err) {
    dispatch({
      type: "ACTION_FAILED",
    });
  }
};

export const getPopularCategories = async (dispatch) => {
  const categoryCollection = query(collection(db, "Categories"), orderBy("categoryName"), limit(4));
  var categoriesFetched = [];
  dispatch({
    type: "START_LOADING",
  });
  try {
    const data = await getDocs(categoryCollection);
    categoriesFetched = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    dispatch({
      type: "GET_POPULAR_CATEGORIES",
      popularCategories: categoriesFetched,
    });
  } catch (err) {
    dispatch({
      type: "ACTION_FAILED",
    });
  }
};

export const addCategory = async (dispatch, categoryName, categoryType, categoryThumbnail) => {
  const tasksCollection = collection(db, "Categories");
  dispatch({
    type: "START_LOADING",
  });
  try {
    await addDoc(tasksCollection, {
      categoryName: categoryName,
       categoryType: categoryType,
       categoryThumbnail: categoryThumbnail
    });
    getCategories(dispatch);
  } catch (err) {
    dispatch({
      type: "ACTION_FAILED",
    });
  }
};

export const updateCategory = async (dispatch, id, categoryName, categoryType, categoryThumbnail) => {
  dispatch({
    type: "START_LOADING",
  });
  const catDoc = doc(db, "Categories", id);
  try {
    await updateDoc(catDoc, { categoryName: categoryName, categoryType: categoryType, categoryThumbnail: categoryThumbnail });
    getCategories(dispatch);
  } catch (err) {
    dispatch({
      type: "ACTION_FAILED",
    });
  }
};

export const deleteCategory = async (dispatch, id) => {
  dispatch({
    type: "START_LOADING",
  });
  const catDoc = doc(db, "Categories", id);
  try {
    await deleteDoc(catDoc, id);
    getCategories(dispatch);
  } catch (err) {
    dispatch({
      type: "ACTION_FAILED",
    });
  }
};

export const getProducts = async (dispatch) => {
  const productCollection = collection(db, "Products");
  var productsFetched = [];
  dispatch({
    type: "START_LOADING",
  });
  try {
    const data = await getDocs(productCollection);
    productsFetched = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    dispatch({
      type: "GET_PRODUCTS",
      products: productsFetched,
    });
  } catch (err) {
    dispatch({
      type: "ACTION_FAILED",
    });
  }
};

export const addProduct = async (
  dispatch,
  productName,
  productPrice,
  productTag,
  productCategory,
  productDescription,
  productThumbnail_1,
  productThumbnail_2,
  productThumbnail_3
) => {
  const productCollection = collection(db, "Products");
  dispatch({
    type: "START_LOADING",
  });
  try {
    await addDoc(productCollection, {
      productName: productName,
      productPrice: productPrice,
      productTag: productTag,
      productCategory: doc(db, "Categories", productCategory),
      productDescription: productDescription,
      productThumbnail_1: productThumbnail_1,
      productThumbnail_2: productThumbnail_2,
      productThumbnail_3: productThumbnail_3,
    });
    getProducts(dispatch);
  } catch (err) {
    dispatch({
      type: "ACTION_FAILED",
    });
  }
};

export const updateProduct = async (
    dispatch,
    id, 
    productName,
    productPrice,
    productTag,
    productCategory,
    productDescription,
    productThumbnail_1,
    productThumbnail_2,
    productThumbnail_3
    ) => {
  dispatch({
    type: "START_LOADING",
  });
  const productDoc = doc(db, "Products", id);
  try {
    await updateDoc(productDoc, { 
        productName: productName,
        productPrice: productPrice,
        productTag: productTag,
        productCategory: doc(db, "Categories", productCategory),
        productDescription: productDescription,
        productThumbnail_1: productThumbnail_1,
        productThumbnail_2: productThumbnail_2,
        productThumbnail_3: productThumbnail_3,
      });
    getProducts(dispatch);
  } catch (err) {
    dispatch({
      type: "ACTION_FAILED",
    });
  }
};

export const deleteProduct = async (dispatch, id) => {
  dispatch({
    type: "START_LOADING",
  });
  const productDoc = doc(db, "Products", id);
  try {
    await deleteDoc(productDoc, id);
    getProducts(dispatch);
  } catch (err) {
    dispatch({
      type: "ACTION_FAILED",
    });
  }
};

export const getBanners = async (dispatch) => {
  const bannerCollection = collection(db, "Banners");
  var bannersFetched = [];
  dispatch({
    type: "START_LOADING",
  });
  try {
    const data = await getDocs(bannerCollection);
    bannersFetched = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    dispatch({
      type: "GET_BANNERS",
      banners: bannersFetched,
    });
  } catch (err) {
    dispatch({
      type: "ACTION_FAILED",
    });
  }
};

export const addBanner = async (dispatch, category, bannerURL) => {
  const bannerCollection = collection(db, "Banners");
  dispatch({
    type: "START_LOADING",
  });
  try {
    await addDoc(bannerCollection, {
      category: doc(db, "Categories", category),
       bannerURL: bannerURL
    });
    getBanners(dispatch);
  } catch (err) {
    dispatch({
      type: "ACTION_FAILED",
    });
  }
};

export const updateBanner = async (dispatch, id, category, bannerURL) => {
  dispatch({
    type: "START_LOADING",
  });
  const bannerDoc = doc(db, "Banners", id);
  try {
    await updateDoc(bannerDoc, { category: doc(db, "Categories", category), bannerURL: bannerURL });
    getBanners(dispatch);
  } catch (err) {
    dispatch({
      type: "ACTION_FAILED",
    });
  }
};

export const deleteBanner = async (dispatch, id) => {
  dispatch({
    type: "START_LOADING",
  });
  const bannerDoc = doc(db, "Banners", id);
  try {
    await deleteDoc(bannerDoc, id);
    getBanners(dispatch);
  } catch (err) {
    dispatch({
      type: "ACTION_FAILED",
    });
  }
};

export const getRequests = async (dispatch) => {
  const requestCollections = collection(db, "Requests");
  var requestsFetched = [];
  dispatch({
    type: "START_LOADING",
  });
  try {
    const data = await getDocs(requestCollections);
    requestsFetched = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    dispatch({
      type: "GET_REQUESTS",
      requests: requestsFetched,
    });
  } catch (err) {
    dispatch({
      type: "ACTION_FAILED",
    });
  }
};

export const addRequest = async (dispatch, fullname, phone, product_link, isAccepted) => {
  const requestCollections = collection(db, "Requests");
  try {
    await addDoc(requestCollections, {
      fullname: fullname,
       phone: phone,
       product_link: product_link,
       isAccepted: isAccepted
    });
    getRequests(dispatch);
  } catch (err) {
    dispatch({
      type: "ACTION_FAILED",
    });
  }
};

export const updateRequest = async (dispatch, id, fullname, phone, product_link, isAccepted) => {

  const requestDoc = doc(db, "Requests", id);
  try {
    await updateDoc(requestDoc, { 
      fullname: fullname,
      phone: phone,
      product_link: product_link,
      isAccepted: isAccepted
     });
    getRequests(dispatch);
  } catch (err) {
    dispatch({
      type: "ACTION_FAILED",
    });
  }
};

export const uploadImage = (dispatch, path, file, item) => {
  dispatch({
    type: "SET_PROGRESS",
    progress: 0
  });
    /** @type {any} */
    const metadata = {
      contentType: "image/jpeg",
    };
  const storage = getStorage();
  const storageRef = ref(storage, path + file.name);
  const uploadTask = uploadBytesResumable(storageRef, file, metadata);

    uploadTask.on(
        "state_changed",
        (snapshot) => {
          var setProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          dispatch({
            type: "SET_PROGRESS",
            progress: setProgress
          });
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
            default:
              console.log("Upload Complete!")
          }
        },
        (error) => {
          switch (error.code) {
            case "storage/unauthorized":
              break;
            case "storage/canceled":
              break;
            case "storage/unknown":
              break;
            default:
              console.log("Unknown Error Occured!")
          }
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            item === 'category'?
            dispatch({
              type: "GET_IMAGEURL",
              imageURL: downloadURL
            }):
            dispatch({
              type: "GET_BANNERURL",
              bannerURL: downloadURL
            })
          });
        }
      );
}

export const getFilteredProducts = async (dispatch, param, value) => {
  const productCollection = collection(db, "Products");
  const q = query(productCollection, where(param, "==", value))
  var filteredProductsFetched = [];
  dispatch({
    type: "START_LOADING",
  });
  try {
    const data = await getDocs(q);
    filteredProductsFetched = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    dispatch({
      type: "GET_FILTERED_PRODUCTS",
      filteredProducts: filteredProductsFetched,
    });
  } catch (err) {
    dispatch({
      type: "ACTION_FAILED",
    });
  }
};

export const requestOrder = async (dispatch, products, subTotal) => {
  dispatch({
    type: "START_LOADING",
  });
  const orderCollections = collection(db, "Orders");
  try {
    await addDoc(orderCollections, {
      uid: auth.currentUser?.uid,
      products: products,
      subTotal: subTotal,
      createdAt: Timestamp.fromDate(new Date()).toDate(),
      status: "Processing"
    });
    dispatch({
      type: "ORDER_SUCCESS",
    }); 
    localForage.clear()
  } catch (err) {
    dispatch({
      type: "ACTION_FAILED",
    });
  }
};

export const getOrders = async (dispatch) => {
  dispatch({
    type: "START_LOADING",
  });
  const orderCollections = collection(db, "Orders");
  const q = query(orderCollections, where("uid", "==", auth.currentUser?.uid))
  var ordersFetched = [];

  try {
    const data = await getDocs(q);
    ordersFetched = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    console.log(ordersFetched)
    dispatch({
      type: "GET_ORDERS",
      orders: ordersFetched,
    });
  } catch (err) {
    dispatch({
      type: "ACTION_FAILED",
    });
  }
};

// export const alterClientCount = async (dispatch) => {
//   const countQuery = collection(db, "ClientCount");
//   try {
//     const data = await addD(countQuery);
//     clientCount = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
//     console.log(clientCount)
//     dispatch({
//       type: "GET_CLIENT_COUNT",
//       clientCount: clientCount,
//     });
//   } catch (err) {
//     dispatch({
//       type: "ACTION_FAILED",
//     });
//   }
// };

export const getClientCount = async (dispatch) => {
  dispatch({
    type: "START_LOADING",
  });
  const countQuery = collection(db, "clientCount");
  try {
    const data = await getDocs(countQuery);
    const clientCount = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))[0].count;
    dispatch({
      type: "GET_CLIENT_COUNT",
      clientCount: clientCount,
    });
  } catch (err) {
    dispatch({
      type: "ACTION_FAILED",
    });
  }
};


export const getSalesBalanceCount = async (dispatch) => {
  dispatch({
    type: "START_LOADING",
  });
  const countQuery = collection(db, "salesBalanceCount");
  try {
    const data = await getDocs(countQuery);
    const salesBalanceCount = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))[0].count;
    dispatch({
      type: "GET_SALES_BALANCE_COUNT",
      salesBalanceCount: salesBalanceCount,
    });
  } catch (err) {
    dispatch({
      type: "ACTION_FAILED",
    });
  }
};


export const getOrderCount = async (dispatch) => {
  dispatch({
    type: "START_LOADING",
  });
  const countQuery = collection(db, "orderCount");
  try {
    const data = await getDocs(countQuery);
    const orderCount = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))[0].count;
    dispatch({
      type: "GET_ORDER_COUNT",
      orderCount: orderCount,
    });
  } catch (err) {
    dispatch({
      type: "ACTION_FAILED",
    });
  }
};


export const getPendingOrderCount = async (dispatch) => {
  dispatch({
    type: "START_LOADING",
  });
  const countQuery = collection(db, "pendingOrderCount");
  try {
    const data = await getDocs(countQuery);
    const pendingOrderCount = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))[0].count;
    dispatch({
      type: "GET_PENDING_ORDER_COUNT",
      pendingOrderCount: pendingOrderCount,
    });
  } catch (err) {
    dispatch({
      type: "ACTION_FAILED",
    });
  }
};

// export const getOrdersByAdmin = async (dispatch) => {
//   dispatch({
//     type: "START_LOADING",
//   });
//   const orderCollections = collection(db, "Orders");

//   const initialQuery = query(collection(db, "Orders"), orderBy("createdAt"), limit(10));
// const documentSnapshots = await getDocs(initialQuery);

// const lastVisible = documentSnapshots.docs[documentSnapshots.docs.length-1];

// const next = query(collection(db, "cities"),
//     orderBy("population"),
//     startAfter(lastVisible),
//     limit(25))

//   const q = query(orderCollections, where("uid", "==", auth.currentUser?.uid))
//   var ordersFetched = [];

//   try {
//     const data = await getDocs(q);
//     ordersFetched = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
//     console.log(ordersFetched)
//     dispatch({
//       type: "GET_ORDERS",
//       orders: ordersFetched,
//     });
//   } catch (err) {
//     dispatch({
//       type: "ACTION_FAILED",
//     });
//   }
// };
