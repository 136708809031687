/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import localForage from "localforage";
import { Link } from "react-router-dom";

export default function ShoppingCart({dim, sw}) {
  const [open, setOpen] = useState(false);
  const [subTotal, setSubTotal] = useState(0)
  const [productsInCart, setProductsInCart] = useState([]);

  useEffect(() => {
    const getCartData = () =>
    localForage
        .getItem("ShoppingCart")
        .then((value) => {
          setProductsInCart(value || []);
          if (productsInCart.length !== 0){
            setSubTotal(productsInCart.reduce((a, b) =>  a + (parseInt(b.price) * parseInt(b.qty)), 0))
          }else{
            setSubTotal(0)
          }
          
        })
        .catch(() => {
          setProductsInCart([]);
          setSubTotal(0)
        });
    getCartData();
  }, [open, productsInCart]);

  const handleCartPreview = () => {
    setOpen(true);
  };

  const handleAddProduct = (product) => {
    const index = productsInCart.findIndex((val) => val.id === product.id)
    const cartRef = productsInCart
    cartRef[index] = {   
      id: product.id,
      name: product.name,
      category: product.category,
      img: product.img,
      price: product.price,
      tag: product.tag,
      qty: parseInt(product.qty) + 1
    }
    setProductsInCart(cartRef)
  }

  const handleMinusProduct = (product) => {
    const index = productsInCart.findIndex((val) => val.id === product.id)
    if (parseInt(productsInCart[index].qty) === 1) handleRemoveProduct(product)
    else{
    const cartRef = productsInCart
    cartRef[index] = { 
      id: product.id,
      name: product.name,
      category: product.category,
      img: product.img,
      price: product.price,
      tag: product.tag,
      qty: parseInt(product.qty) - 1
    }
    setProductsInCart(cartRef)
    }
  }

  const handleRemoveProduct = (product) => {
    const index = productsInCart.findIndex((val) => val.id === product.id)
    const cartRef = productsInCart
    cartRef.splice(index, 1)
    setProductsInCart(cartRef)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    localForage.setItem("ShoppingCart", productsInCart).then((value) => {
      if (value.length === 0){
        setProductsInCart([])
        setSubTotal(0)
      }
    }).catch((err)=>{
      console.log(err)
    })

  }

  return (
    <>
      {/* <div
        className="group -m-2 p-2 flex items-center cursor-pointer"
        onClick={handleCartPreview}
      >
        <ShoppingBagIcon
          className="flex-shrink-0 h-6 w-6 text-white group-hover:text-secondary"
          aria-hidden="true"
        />
        <span className="ml-2 text-sm font-medium text-white group-hover:text-secondary">
          {productsInCart.length}
        </span>
        <span className="sr-only">items in cart, view bag</span>
      </div> */}
      <div
                            onClick={handleCartPreview}

                    className="dark:text-white cursor-pointer text-gray-800 flex items-center space-x-2 focus:outline-none focus:ring-2 focus:ring-gray-800 hover:underline"
                  >
                    <div className="flex">
                      <svg
                        className="fill-stroke"
                        width={dim}
                        height={dim}
                        viewBox={`0 0 ${dim} ${dim}`}
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4.33333 1L1 5V19C1 19.5304 1.23413 20.0391 1.65087 20.4142C2.06762 20.7893 2.63285 21 3.22222 21H18.7778C19.3671 21 19.9324 20.7893 20.3491 20.4142C20.7659 20.0391 21 19.5304 21 19V5L17.6667 1H4.33333Z"
                          stroke="currentColor"
                          strokeWidth={sw}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M1 5H21"
                          stroke="currentColor"
                          strokeWidth={sw}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M15.4436 9C15.4436 10.0609 14.9753 11.0783 14.1418 11.8284C13.3083 12.5786 12.1779 13 10.9991 13C9.82039 13 8.68993 12.5786 7.85643 11.8284C7.02294 11.0783 6.55469 10.0609 6.55469 9"
                          stroke="currentColor"
                          strokeWidth={sw}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <span className="text-sm p-1 lg:pl-0 font-medium text-featured">
          {productsInCart.length}
        </span>
                    </div>
                    <p className="text-base lg:hidden md:hidden">Cart</p>
                  </div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 overflow-hidden"
          onClose={setOpen}
        >
          <div className="absolute inset-0 overflow-hidden">
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-500"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-500"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div className="w-screen max-w-md">
                  <div className="h-full flex flex-col bg-background2 shadow-xl overflow-y-scroll">
                    <div className="flex-1 py-6 overflow-y-auto px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-lg font-medium text-background3">
                          Shopping cart
                        </Dialog.Title>
                        <div className="ml-3 h-7 flex items-center">
                          <button
                            type="button"
                            className="-m-2 p-2 text-background3 hover:text-background3"
                            onClick={() => setOpen(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>

                      <div className="mt-8">
                        <div className="flow-root">
                          <ul
                            role="list"
                            className="-my-6 divide-y divide-gray-200"
                          >
                            {productsInCart.map((product) => (
                              <li key={product.id} className="py-6 flex">
                                <div className="flex-shrink-0 w-24 h-24 border border-gray-200 rounded-md overflow-hidden">
                                  <img
                                    src={product.img}
                                    alt={product.name}
                                    className="w-full h-full object-center object-cover"
                                  />
                                </div>

                                <div className="ml-4 flex-1 flex flex-col">
                                  <div>
                                    <div className="flex justify-between text-base font-medium text-background3">
                                      <h3>{product.name}</h3>
                                      <p className="ml-4">
                                        NRs{" "}
                                        {parseInt(product.price) *
                                          parseInt(product.qty)}
                                      </p>
                                    </div>
                                    <p className="mt-1 text-sm text-gray-400">
                                      {product.category}
                                    </p>
                                  </div>

                                  <form onSubmit={handleSubmit} className="flex-1 flex items-end justify-between text-sm">
                                    <p className="text-gray-200">Qty</p>
          
                                    <input type="submit" className="hidden" id="cart-operation"/>

                                    <div className="flex-1 flex justify-center">
                                      <button className="cursor-pointer" type="submit" onClick={() => handleAddProduct(product)}>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-5 w-5 text-gray-400"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        htmlFor="cart-operation"
                                      >
                                        <path
                                          fillRule="evenodd"
                                          d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                                          clipRule="evenodd"
                                        />
                                      </svg>
                                      </button>
                                      <p className="text-sm mx-2 text-white">
                                        {product.qty}
                                      </p>
                                      <button className="cursor-pointer" type="submit" onClick={() => handleMinusProduct(product)}>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-5 w-5 text-gray-400"
                                        viewBox="0 0 20 20"
                                        htmlFor="cart-operation"
                                        fill="currentColor"
                                      >
                                        <path
                                          fillRule="evenodd"
                                          d="M5 10a1 1 0 011-1h8a1 1 0 110 2H6a1 1 0 01-1-1z"
                                          clipRule="evenodd"
                                        />
                                      </svg>
                                      </button>
                                    </div>

                                    <div className="flex">
                                      <button
                                        htmlFor="cart-operation"
                                        type="submit"
                                        className="font-medium text-primary hover:text-secondary"
                                        onClick={() => handleRemoveProduct(product)}
                                      >
                                        Remove
                                      </button>
                                    </div>
                                    {/* <input type="submit" className="hidden"/> */}
                                  </form>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                    {
                      productsInCart.length === 0 ?
                    <div className="border-t border-gray-200 py-6 px-4 sm:px-6">
<p className="my-2 text-lg text-center text-background3">
                        No Items in cart!
                      </p>
                      <div className="mt-6 text-sm text-center text-background3">
                        <p>
                          <button
                            type="button"
                            className="text-primary font-medium hover:text-secondary"
                            onClick={() => setOpen(false)}
                          >
                            Continue Shopping
                            <span aria-hidden="true"> &rarr;</span>
                          </button>
                        </p>
                      </div>
                      </div>
                    :
                    <div className="border-t border-gray-200 py-6 px-4 sm:px-6">
                      <div className="flex justify-between text-base font-medium text-background3">
                        <p>Subtotal</p>
                        <p>NRs {subTotal}</p>
                      </div>
                      <p className="mt-0.5 text-sm text-background3">
                        Shipping charges will be calculated at checkout.
                      </p>
                      <div className="mt-6">
                        <Link
                          to="/user/checkout"
                          onClick={() => setOpen(false)}
                          className="flex justify-center items-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-primary hover:bg-secondary"
                        >
                          Checkout
                        </Link>
                      </div>
                      <div className="mt-6 flex justify-center text-sm text-center text-background3">
                        <p>
                          or{" "}
                          <button
                            type="button"
                            className="text-primary font-medium hover:text-secondary"
                            onClick={() => setOpen(false)}
                          >
                            Continue Shopping
                            <span aria-hidden="true"> &rarr;</span>
                          </button>
                        </p>
                      </div>
                    </div>}
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
