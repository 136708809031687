import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import LinearProgress from "@mui/material/LinearProgress";
import Backdrop from "@mui/material/Backdrop";
import { Box } from "@mui/material";

export const Spinner = () => {
  return (
<div class="flex justify-center items-center">
  <div class="spinner-grow inline-block w-8 h-8 bg-background2 rounded-full opacity-0" role="status">
    <span class="hidden">Loading...</span>
  </div>
</div>
  );
};

export const Loading = () => {
  return (
    <div className="h-8 w-8 z-50 flex justify-center items-center">
      <div className="animate-spin rounded-full h-8 w-8 border-t-4 items-center border-b-2 border-background2"></div>
    </div>
  );
};

export const SimpleBackdrop = () => {
  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export const CardSkeleton = () => {
  return (
    <div className="w-68 lg:mb-0 mb-8 border shadow-lg">
      <div className="bg-white rounded">
        <div className="bg-gray-200 p-4 overflow-hidden w-full h-60 animate-pulse"> </div>
        <div className="h-28 p-3">
          <div className="grid grid-cols-3 gap-4 mt-2">
            <div className="h-8 col-span-3 bg-gray-200 rounded animate-pulse"></div>
            <div className="h-8 col-span-2 bg-gray-200 rounded animate-pulse"></div>
            <div className=" h-8 bg-gray-200 rounded animate-pulse"></div>
            <div className="..."></div>
            <div className="col-span-2 ..."></div>
          </div>
        </div>
      </div>
    </div>
  );
};
