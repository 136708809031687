import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { Editor } from "@tinymce/tinymce-react";

import DialogTitle from "@mui/material/DialogTitle";
import {
  Box,
  Divider,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { addProduct, getCategories, updateProduct } from "../ContextAPI/Action";
import { useAuthState } from "../ContextAPI/Context";
import { ImageUpload } from "./ImageUpload";
import Title from "./Title";


export default function CreateProduct(props) {

  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);

  const [productName, setProductName] = useState(props.prevProductName);
  const [productTag, setProductTag] = useState(props.prevProductTag);
  const [productPrice, setProductPrice] = useState(props.prevProductPrice);
  const [productCategory, setProductCategory] = useState(
    props.prevProductCategory
  );
  const [productDescription, setProductDescription] = useState(
    props.prevProductDescription
  );

  const [{ categories, thumbnail1URL, thumbnail2URL, thumbnail3URL }, dispatch] = useAuthState();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleNameChange = (e) => {
    setProductName(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      productName === props.prevProductName &&
      thumbnail1URL === props.prevProductThumbnail_1 &&
      thumbnail2URL === props.prevProductThumbnail_2 &&
      thumbnail3URL === props.prevProductThumbnail_3 &&
      productCategory === props.prevProductCategory &&
      productPrice === props.prevProductPrice &&
      productTag === props.prevProductTag &&
      productDescription === props.prevProductDescription
    ) setOpen(true)
    else{
      if(props.edit)
        updateProduct(dispatch,
          props.id,
          productName,
          productPrice,
          productTag,
          productCategory,
          productDescription,
          thumbnail1URL,
          thumbnail2URL,
          thumbnail3URL
          )
        else{
           addProduct(
            dispatch,
            productName,
            productPrice,
            productTag,
            productCategory,
            productDescription,
            thumbnail1URL,
          thumbnail2URL,
          thumbnail3URL
          );
          setProductName("");
          setProductPrice("");
          setProductTag("");
          setProductCategory("");
          setProductDescription("");
           }
          handleClose()
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {props.edit ? (
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={handleClickOpen}
        >
          Edit
        </Button>
      ) : (
        <Button variant="outlined" color="primary" onClick={handleClickOpen}>
          Create New Product
        </Button>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <form onSubmit={handleSubmit} id="productcreate_form">
          <DialogTitle id="form-dialog-title">
            {props.edit ? "Update Product" : "Create New Product"}
          </DialogTitle>
          <Divider />
          <DialogContent>
          <Box
              sx={{
                "& > :not(style)": { m: 1, width: "28ch" },
              }}
              autoComplete="off"
            >
              <TextField
                autoFocus
                margin="dense"
                value={productName}
                required
                onChange={handleNameChange}
                label="Name of the product"
                type="text"
              />
              <TextField
              autoFocus
              margin="dense"
                value={productPrice}
                required
                onChange={(e) => setProductPrice(e.target.value)}
                label="Price"
                type="number"
                InputProps={{
                  startAdornment: <InputAdornment position="start">Rs </InputAdornment>,
                }}
              />
            <FormControl>
              <InputLabel>Tag</InputLabel>
              <Select
                value={productTag}
                label="Tag"
                onChange={(e) => setProductTag(e.target.value)}
              >
                  <MenuItem value="New">
                    New
                  </MenuItem>
                  <MenuItem value="Featured">
                    Featured
                  </MenuItem>
                  <MenuItem value="Sale">
                    Sale
                  </MenuItem>
                  <MenuItem value="None">
                    None
                  </MenuItem>
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel>Category</InputLabel>
              <Select
                value={productCategory}
                label="Category"
                onChange={(e) => setProductCategory(e.target.value)}
              >
                {categories.map(({ id, categoryName }) => (
                  <MenuItem value={id || ""} key={id}>
                    {categoryName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <ImageUpload prevProductThumbnail_1={props.prevProductThumbnail_1} prevProductThumbnail_2={props.prevProductThumbnail_2} prevProductThumbnail_3={props.prevProductThumbnail_3}/>
            </FormControl>
            </Box>
            <FormControl sx={{marginTop: '10px'}} fullWidth>
                <Title>Description</Title>
            <Editor
              apiKey="kck6txzugrflg6zurhyj1u89qzelzx05spfjcpueey21fa95"
              value={productDescription}
              init={{
                height: 500,
                menubar: false,
                plugins: [
                  "advlist autolink lists link image charmap print preview anchor",
                  "searchreplace visualblocks code fullscreen",
                  "insertdatetime media table paste code help wordcount",
                ],
                toolbar:
                  "undo redo | formatselect | " +
                  "bold italic backcolor | alignleft aligncenter " +
                  "alignright alignjustify | bullist numlist outdent indent | " +
                  "removeformat | help",
                content_style:
                  "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
              }}
              onEditorChange={(newValue, editor) =>
                setProductDescription(newValue)
              }
            />
            </FormControl>
          </DialogContent>
        </form>
        <DialogActions>
          <Button onClick={handleClose} variant="contained">
            Cancel
          </Button>
          <Button
            type="submit"
            form="productcreate_form"
            variant="contained"
            color="primary"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
