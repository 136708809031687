import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyClk1uxnAv5kVUwo71voe-9FTtHSESZANc",
  authDomain: "accessories-np-61703.firebaseapp.com",
  projectId: "accessories-np-61703",
  storageBucket: "accessories-np-61703.appspot.com",
  messagingSenderId: "877567494021",
  appId: "1:877567494021:web:c7dc0a68fe02c088c1a6ec",
  measurementId: "G-KLXL71MB99"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
export const storage = getStorage(app);
export const auth = getAuth();

export default db