import { Box, Grid } from '@mui/material'
import React, { useEffect } from 'react'
import { getBanners } from '../ContextAPI/Action'
import { useAuthState } from '../ContextAPI/Context'
import BannerCard from './BannerCard'
import CreateBanner from './CreateBanner'
import Title from './Title'

const Banners = () => {
    const [{banners}, dispatch] = useAuthState()

    useEffect(()=> {
        getBanners(dispatch)
    },[])

    console.log(banners)
    return (
        <Box sx={{ flexGrow: 1,padding: 4, marginTop: 8 }}>
        <CreateBanner edit={false} id="none" prevCategory="" prevBannerURL=""/>
            <br/>
            <Title>Banners</Title>
            <Grid container spacing={3}>
              {
                banners && banners.map((value) => (
                  <Grid item xs={12}  key={value.id}>
                      <BannerCard banner={value} dispatch={dispatch}/>
                  </Grid>
                ))
              }
              
            </Grid>
          </Box>
    )
}

export default Banners
