import React, { useEffect } from 'react';
import Title from './Title';
import CreateCategory from './CreateCategory';
import { useAuthState } from '../ContextAPI/Context';
import { getCategories } from '../ContextAPI/Action';
import { Box, Grid } from '@mui/material';
import CategoryCard from './CategoryCard';

export default function Categories() {
  const [{categories}, dispatch] = useAuthState();

  useEffect(() => {
    getCategories(dispatch)
  },[dispatch])

  return (
          <Box sx={{ flexGrow: 1,padding: 4, marginTop: 8 }}>
      <CreateCategory edit={false} id="none" prevCategoryName="" prevCategoryType=""/>
          <br/>
          <Title>Categories</Title>
          <Grid container spacing={3}>
            {
              categories && categories.map((value) => (
                <Grid item xs={12} sm={3} key={value.id}>
                    <CategoryCard category={value} dispatch={dispatch}/>
                </Grid>
              ))
            }
            
          </Grid>
        </Box>
  );
}
