import { Divider } from "@mui/material";
import { getDoc } from "firebase/firestore";
import * as React from "react";
import { deleteBanner } from "../ContextAPI/Action";
import CreateBanner from "./CreateBanner";
import DeleteItem from "./DeleteItem";

export default function BannerCard({ banner, dispatch }) {
  const [category, setCategory] = React.useState('')

  React.useEffect(() => {
  const fetchData = async() => {
  const docSnap = await getDoc(banner.category);
  setCategory(docSnap.data().categoryName)
  }
    fetchData()
  },[])

  return (
    <React.Fragment>
      <div
        className="bg-gray-100 m-auto w-2/3 h-96 mt-5"
        style={{
          backgroundImage: `url(${banner.bannerURL})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="flex flex-row items-end h-full w-full">
          <div className="flex flex-col w-full pb-3 pt-10 px-3 bg-gradient-to-t from-black text-gray-200">
            <div className="flex justify-between">
              <h5 className="text-base leading-5">{category}</h5>
              <div className="w-max inline-flex items-center">
                <CreateBanner
                  edit="true"
                  id={banner.id}
                  prevCategory={banner.category.id}
                  prevBannerURL={banner.bannerURL}
                />
                <DeleteItem
                  deleteFun={() => deleteBanner(dispatch, banner.id)}
                  item={"this banner"}
                  icon={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Divider />
    </React.Fragment>
  );
}
