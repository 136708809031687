import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import ProductCard from './ProductCard';
import CreateProduct from './CreateProduct';
import { getProducts } from '../ContextAPI/Action';
import { useAuthState } from '../ContextAPI/Context';
import { styled, useTheme } from '@mui/material/styles';
import { Box, Paper } from '@mui/material';

// const Item = styled(Paper)(({ theme }) => ({
//   ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: 'center',
//   color: theme.palette.text.secondary,
// }));

export default function Products() {
  const theme = useTheme();

  const [{products}, dispatch] = useAuthState()

  useEffect(() => {
    getProducts(dispatch)
  }, [])
  return (
    <Box sx={{ flexGrow: 1,padding: 4, marginTop: 10 }}>
      <CreateProduct  edit={false} id="none" prevProductPrice=""  prevProductTag=""  prevProductCategory=""  prevProductName=""  prevProductDescription=""  prevProductThumbnail=""/>
      <br/>
      <Grid container spacing={3}>
        {
          products && products.map((value) => (
            <Grid item xs={12} sm={3} key={value.id}>
                <ProductCard product={value} dispatch={dispatch}/>
            </Grid>
          ))
        }
      </Grid>
    </Box>
  );
}
