export const initialState = {
  categories: [],
  popularCategories: [],
  clientCount: 0,
  salesBalanceCount: 0,
  orderCount: 0,
  pendingOrderCount: 0,
  products: [],
  banners: [],
  requests: [],
  orders: [],
  imageURL: "",
  bannerURL: "",
  thumbnail1URL: "",
  thumbnail2URL: "",
  thumbnail3URL: "",
  loading: false,
  isAuthenticated: false,
  isUserAuthenticated: false,
  orderPlaced: false,
  error: false,
  success: false,
  progress: 0,
};

export const reducer = (initialState, action) => {
  switch (action.type) {
    case "START_LOADING":
      return {
        ...initialState,
        loading: true,
      };
    case "REGISTRARTION_SUCCESS":
      return {
        ...initialState,
        loading: false,
      };
    case "LOGIN_SUCCESS":
      return {
        ...initialState,
        loading: false,
        isAuthenticated: true,
      };
      case "USER_LOGIN_SUCCESS":
        return {
          ...initialState,
          loading: false,
          isUserAuthenticated: true,
        };
    case "LOGOUT_SUCCESS":
      return {
        ...initialState,
        loading: false,
        isAuthenticated: false,
      };
    case "GET_CATEGORIES":
      return {
        ...initialState,
        categories: action.categories,
        loading: false,
      };
      case "GET_CLIENT_COUNT":
        return {
          ...initialState,
          clientCount: action.clientCount,
          loading: false,
        };
        case "GET_SALES_BALANCE_COUNT":
          return {
            ...initialState,
            salesBalanceCount: action.salesBalanceCount,
            loading: false,
          };
          case "GET_ORDER_COUNT":
            return {
              ...initialState,
              orderCount: action.orderCount,
              loading: false,
            };
            case "GET_PENDING_ORDER_COUNT":
            return {
              ...initialState,
              pendingOrderCount: action.pendingOrderCount,
              loading: false,
            };
      case "GET_POPULAR_CATEGORIES":
        return {
          ...initialState,
          popularCategories: action.popularCategories,
          loading: false,
        };
      case "GET_PRODUCTS":
        return {
          ...initialState,
          products: action.products,
          loading: false,
        };
    case "GET_BANNERS":
      return {
        ...initialState,
        banners: action.banners,
        loading: false,
        orderPlaced: false
      };
    case "GET_REQUESTS":
      return {
        ...initialState,
        requests: action.requests,
        loading: false,
      };
      case "GET_ORDERS":
        return {
          ...initialState,
          orders: action.orders,
          loading: false,
        };
    case "GET_THUMBNAIL1URL":
      return {
        ...initialState,
        thumbnail1URL: action.thumbnail1URL,
      };
    case "GET_THUMBNAIL2URL":
      return {
        ...initialState,
        thumbnail2URL: action.thumbnail2URL,
      };
    case "GET_THUMBNAIL3URL":
      return {
        ...initialState,
        thumbnail3URL: action.thumbnail3URL,
      };
    case "GET_IMAGEURL":
      return {
        ...initialState,
        imageURL: action.imageURL,
      };
    case "GET_BANNERURL":
      return {
        ...initialState,
        bannerURL: action.bannerURL,
      };

    case "SET_PROGRESS":
      return {
        ...initialState,
        progress: action.progress,
      };
      case "ORDER_SUCCESS":
      return {
        ...initialState,
        loading: false,
        orderPlaced: true
      };
    case "ACTION_FAILED":
      return {
        ...initialState,
        loading: false,
        error: true,
      };
    case "UNDO":
      return {
        ...initialState,
        error: false,
        success: false,
      };
    default:
      return initialState;
  }
};
