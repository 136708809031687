import React from 'react';
import Switch from '@mui/material/Switch';
import { useAuthState } from '../ContextAPI/Context';
import { Loading } from '../Components/Loading';

export default function RequestSwitch ({id, fullname, phone, product_link, isAccepted, updateFun}) {
    const [{}, dispatch] = useAuthState()
  const [state, setState] = React.useState(isAccepted)
  const [loading, setLoading] = React.useState(false)


  const handleChange = (event) => {
    setState(event.target.checked);
    handleSubmit(event)
  };

  const handleSubmit = (e) => {
      e.preventDefault()
      updateFun(dispatch, id, fullname, phone, product_link, !state)
  }
  return (
    <>
    <form onSubmit={handleSubmit}>
      {
        loading ? <Loading />:
      <div>
      <Switch
        checked={state}
        name="state"
        onChange={(e) => handleChange(e)}
        value={state}
      />
      <input type="submit" className="hidden"/>
      </div>
    }
    </form>

    </>
  );
}