import React from 'react'
import CreateCategory from './CreateCategory'
import { deleteCategory } from '../ContextAPI/Action';
import DeleteItem from './DeleteItem';

const CategoryCard = ({category, dispatch}) => {
    return (
        <div className="bg-white shadow-xl rounded-lg max-w-sm ">
        <a href="#">
            <img className="object-contain h-48 w-96 rounded-t-lg" src={category.categoryThumbnail} alt="category-img" />
        </a>
        <div className="p-5">
        <p className="text-sm text-gray-700 ">{category.categoryType}</p>
                <h5 className="text-gray-900 font-bold text-lg tracking-tight mb-2 ">{category.categoryName}</h5>
            <div className='flex justify-around'>
            <CreateCategory edit="true" id={category.id} prevCategoryName={category.categoryName} prevCategoryType={category.categoryType} prevCategoryThumbnail={category.categoryThumbnail}/>
            <DeleteItem deleteFun={() => deleteCategory(dispatch, category.id)} item={category.categoryName} icon={true}/>
            </div>

        </div>
    </div>
    )
}

export default CategoryCard
