import { getAuth, onAuthStateChanged } from "firebase/auth";
import React, {
  createContext,
  useReducer,
  useContext,
  useState,
  useEffect,
} from "react";
import { initialState, reducer } from "./Reducer";

export const AuthStateContext = createContext();

export const AuthProvider = ({ children }) => {
//   const [user, setUser] = useState('');
//   const auth = getAuth();

//   useEffect(() => {
//     const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
//       setUser(currentUser);
//     });
//     return () => {
//       unsubscribe();
//     };
//   }, []);

  return (
    <AuthStateContext.Provider
      value={(useReducer(reducer, initialState))}
    >
      {children}
    </AuthStateContext.Provider>
  );
};

export const useAuthState = () => useContext(AuthStateContext);
