import React from "react";

const modes = [
    {index: 1, name: "Esewa", link: "#link1"},
    {index: 2, name: "Khalti", link: "#link2"},
    {index: 3, name: "IME Pay", link: "#link3"},
    {index: 4, name: "Bank Transfer", link: "#link4"},
]
const Tabs = () => {
  const [openTab, setOpenTab] = React.useState(1);
  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full">
          <ul
            className="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row"
            role="tablist"
          >
              {
                  modes.map((mode) => (
                    <li className="-mb-px mr-2 last:mr-0 flex-auto text-center" key={mode.index}>
                    <a
                      className={
                        "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                        (openTab === mode.index
                          ? "text-white bg-secondary"
                          : "text-primary")
                      }
                      onClick={e => {
                        e.preventDefault();
                        setOpenTab(mode.index);
                      }}
                      data-toggle="tab"
                      href={mode.link}
                      role="tablist"
                    >
                      {mode.name}
                    </a>
                  </li>
                  ))
              }
          </ul>
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6">
            <div className="px-4 py-5 flex-auto">
              <div className="tab-content tab-space">
                <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                  <p>
                    Collaboratively administrate empowered markets via
                    plug-and-play networks. Dynamically procrastinate B2C users
                    after installed base benefits.
                    <br />
                    <br /> Dramatically visualize customer directed convergence
                    without revolutionary ROI.
                  </p>
                </div>
                <div className={openTab === 2 ? "block" : "hidden"} id="link2">
                  <p>
                    Completely synergize resource taxing relationships via
                    premier niche markets. Professionally cultivate one-to-one
                    customer service with robust ideas.
                    <br />
                    <br />
                    Dynamically innovate resource-leveling customer service for
                    state of the art customer service.
                  </p>
                </div>
                <div className={openTab === 3 ? "block" : "hidden"} id="link3">
                  <p>
                    Efficiently unleash cross-media information without
                    cross-media value. Quickly maximize timely deliverables for
                    real-time schemas.
                    <br />
                    <br /> Dramatically maintain clicks-and-mortar solutions
                    without functional solutions.
                  </p>
                </div>
                <div className={openTab === 4 ? "block" : "hidden"} id="link4">
                  <p>
                  Efficiently unleash cross-media information without
                    cross-media value. Q
                    Efficiently unleash cross-media information without
                    cross-media value. Quickly maximize timely deliverables for
                    real-time schemas.
                    <br />
                    <br /> Dramatically maintain clicks-and-mortar solutions
                    without functional solutions.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default function PaymentInfo() {
  return (
    <>
      <Tabs />
    </>
  );
}