import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import EditIcon from "@mui/icons-material/Edit";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { addCategory, updateCategory, uploadImage } from "../ContextAPI/Action";
import { useAuthState } from "../ContextAPI/Context";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import Title from "./Title";
import { data } from "autoprefixer";

export default function CreateCategory(props) {
  const [open, setOpen] = useState(false);
  const [categoryName, setCategoryName] = useState(props.prevCategoryName);
  const [categoryType, setCategoryType] = useState(props.prevCategoryType);
  const [selectedImg, setSelectedImg] = useState('');

  const [{ progress, imageURL }, dispatch] = useAuthState();

  const handleClickOpen = () => {
    setOpen(true);
  };
  useEffect(() => {
      dispatch({
    type: "GET_IMAGEURL",
    imageURL: props.prevCategoryThumbnail
  });
  },[])



  const handleInputChange = (e) => {
    setCategoryName(e.target.value);
  };

  const handleImgChange = (e) => {
    dispatch({
      type: "SET_PROGRESS",
      progress: 0
    });
    setSelectedImg(e.target.files[0])
  };

  const handleImageUpload = async() => {
   await uploadImage(dispatch, "categories/", selectedImg, 'category')
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      categoryName === props.prevCategoryName &&
      categoryType === props.prevCategoryType &&
      imageURL === props.prevCategoryThumbnail
    )handleClose()
    else{
      if (props.edit) updateCategory(dispatch, props.id, categoryName, categoryType, imageURL)
      else{
        addCategory(dispatch, categoryName, categoryType, imageURL);
      setCategoryName("");
      setCategoryType("");
      setSelectedImg("");
      } 
      handleClose()
    }
  }
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {props.edit ? (
        <IconButton color="primary" onClick={handleClickOpen}>
          <EditIcon />
        </IconButton>
      ) : (
        <Button variant="outlined" color="primary" onClick={handleClickOpen}>
          Create New Category
        </Button>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
      >
        <form onSubmit={handleSubmit} id="category_form">
          <DialogTitle >
            {props.edit ? "Update Category" : "Create New Category"}
          </DialogTitle>
          <DialogContent>
            <Box
              sx={{
                "& > :not(style)": { m: 1, width: "25ch" },
              }}
              autoComplete="off"
            >
              <TextField
                autoFocus
                margin="dense"
                id="categoryName"
                type="text"
                label="Category Name"
                value={categoryName}
                onChange={handleInputChange}
                fullWidth
              />
              <FormControl fullWidth>
                <InputLabel>Category Type</InputLabel>

              <Select
                value={categoryType}
                label="Category Type"
                onChange={(e) => setCategoryType(e.target.value)}
              >
                <MenuItem value="Male">Male</MenuItem>
                <MenuItem value="Female">Female</MenuItem>
                <MenuItem value="Game">Games</MenuItem>
                <MenuItem value="Electronics">Electronics</MenuItem>
                <MenuItem value="Subscription">Subscriptions</MenuItem>
                <MenuItem value="Others">Others</MenuItem>
              </Select>
              </FormControl>
      {selectedImg && (
        <>
        <Title style={{marginTop: '15px'}}>Selected Image</Title>
            <img style={{maxHeight: 225, maxWidth: 225}} src={URL.createObjectURL(selectedImg)} alt="product selectedImg" />
            </>
        )}
      {!selectedImg && props.prevCategoryThumbnail &&(
        <>
        <Title style={{marginTop: '15px'}}>Selected Image</Title>

            <img style={{maxHeight: 225, maxWidth: 225}} src={props.prevCategoryThumbnail} alt="product selectedImg" />
            </>
        )}
        <input
        accept="image/*"
        style={{display: 'none', marginTop: '10px'}}
        id="contained-button-file"
        type="file"
        onChange={handleImgChange}
      />
      <br/>
      <Stack direction="column" spacing={1}>
      <label htmlFor="contained-button-file">
        <Button variant="contained" sx={{width: 225 }} startIcon={<PhotoCamera />} component="span">
          Select Thumbnail
        </Button>
      </label>
      <label>
      <Button
      style={{width: 225 }}
    onClick={handleImageUpload}
      disabled={!selectedImg || (Math.round(progress) < 0) || (Math.round(progress) === 100)}
        variant="contained"
        color="success"
        startIcon={<CloudUploadIcon />}
      >
        Upload
      </Button>
      </label>
      </Stack>
      {selectedImg && <Typography variant="body2" sx={{marginTop: 1}} gutterBottom>{selectedImg.name}</Typography>}
      {progress !== 0 && <Typography variant="body2">{Math.round(progress)}%</Typography>}
            </Box>
          </DialogContent>
        </form>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            type="submit"
            disabled={!categoryName || !categoryType}
            form="category_form"
            color="primary"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
