import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import db from "../firebase";
import Breadcrumbs from "../Components/Breadcrumbs";
import parse from "html-react-parser";
import { CardSkeleton } from "../Components/Loading";
import localForage from "localforage";

export default function ProductPreview() {
  const { slug, productName } = useParams();
  const [categoryName, setCategoryName] = useState("");
  const [btnClicked, setBtnClicked] = useState(false);
  const [productsInCart, setProductsInCart] = useState([]);
  const navigate = useNavigate();
  const [product, setProduct] = useState({});

  useEffect(() => {
    const getProductData = async () => {
      const productRef = doc(db, "Products", slug);
      const productSnap = await getDoc(productRef);

      if (productSnap.exists()) {
        setProduct(productSnap.data());
      } else {
        navigate("/");
      }
    };
    getProductData();
  }, []);

  useEffect(() =>{
    const getCartData = () =>
      localForage
        .getItem("ShoppingCart")
        .then((value) => {
          setProductsInCart(value || [])
        })
        .catch(() => {
          setProductsInCart([]);
        });
    getCartData();
  },[productsInCart])

  useEffect(() => {
    const fetchData = async () => {
      const docRef = doc(db, "Categories", product.productCategory.id);
      const docSnap = await getDoc(docRef);
      setCategoryName(docSnap.data().categoryName);
    };
    fetchData();
  }, [product]);

  const handleSubmit = (e) => {
    e.preventDefault()
    localForage.setItem("ShoppingCart", productsInCart).then((value) => {
      setBtnClicked(true)
            setTimeout(() => {
                setBtnClicked(false)
            }, 2000)
    });
  }

  const handleAddtoCart = () => {
    const filteredProduct = productsInCart.filter((product) => product.id === slug)
    var qty = 1
    if (filteredProduct.length !== 0){
      const index = productsInCart.findIndex((product) => product.id === slug)
      const cartRef = productsInCart
      cartRef[index] = {   
        id: slug,
        name: product.productName,
        category: categoryName,
        img: product.productThumbnail_1,
        price: product.productPrice,
        tag: product.productTag,
        qty: parseInt(filteredProduct[0].qty) + 1
      }
      setProductsInCart(cartRef)
    }else{
      setProductsInCart((productsInCart) => [
      ...productsInCart,
      {
        id: slug,
        name: product.productName,
        category: categoryName,
        img: product.productThumbnail_1,
        price: product.productPrice,
        tag: product.productTag,
        qty: qty
      },
    ]);
    }
    
  };

  if (Object.keys(product).length === 0)
    return (
      <div className="bg-white mt-1">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-2xl mx-auto py-20 lg:py-24 lg:max-w-none">
            <Breadcrumbs child={productName} />
            <div className="space-y-4 mt-4">
              <CardSkeleton />
            </div>
          </div>
        </div>
      </div>
    );
  return (
    <div className="py-12 2xl:px-4 md:px-6 px-4 ">
      <div className="md:flex items-start justify-center">
        <div className="xl:w-2/6 lg:w-2/5 w-80 md:block hidden">
          <Breadcrumbs child={productName} className="my-2"/>
          <img
            className="w-full mt-2"
            alt={product.productName}
            src={product.productThumbnail_1}
          />
          <div className="flex justify-start">
            <img
              className="mt-6 w-1/2 p-1"
              alt={product.productName}
              src={product.productThumbnail_2}
            />
            <img
              className="mt-6 w-1/2 p-1"
              alt={product.productName}
              src={product.productThumbnail_3}
            />
          </div>
        </div>
        <div className="md:hidden">
          <Breadcrumbs child={productName} />

          <img
            className="w-full"
            alt={product.productName}
            src={product.productThumbnail_1}
          />
          <div className="flex items-center justify-between mt-3 space-x-4 md:space-x-0">
            <img
              alt={product.productName}
              className="mt-4 w-1/2 p-1"
              src={product.productThumbnail_2}
            />
            <img
              alt={product.productName}
              className="mt-4 w-1/2 p-1"
              src={product.productThumbnail_3}
            />
          </div>
        </div>
        <div className="xl:w-2/5 md:w-1/2 lg:ml-8 md:ml-6 md:mt-0 mt-6">
          <div className="pb-2">
            {product.productTag !== "None" && (
              <div
                className={`${
                  product.productTag === "Featured"
                    ? "bg-featured w-24"
                    : "New"
                    ? "bg-indigo-600 w-16"
                    : "bg-red-600 w-16"
                } text-white py-1.5 px-4 rounded-full text-center mt-8`}
              >
                <p className="text-sm p-auto m-auto">{product.productTag}</p>
              </div>
            )}
            <h1
              className="
      lg:text-2xl
      text-xl
      font-semibold
      lg:leading-6
      leading-7
      text-primary
      mt-3
    "
            >
              {product.productName}
            </h1>
            <p className="text-md leading-4 text-gray-600 mt-4 mb-4">
              {categoryName}
            </p>
            <h6 className="text-lg leading-4 text-featured font-medium">
              NRs {product.productPrice}
            </h6>
          </div>

          <div className="my-8">
            <p className="text-md border-b border-gray-200 leading-4 text-gray-600 mt-4 pb-3 mb-3">
              Product Description
            </p>

            {parse(product.productDescription)}
          </div>
          <form onSubmit={handleSubmit}>
            <input type="submit" className="hidden"/>
          <button
            className="
    focus:outline-none focus:ring-2 focus:ring-offset-2 
    text-base
    flex
    items-center
    justify-center
    leading-none
    text-white
    bg-primary
    w-full
    py-4
    hover:bg-secondary
  "
            onClick={handleAddtoCart}
          >
            Add to Cart
          </button>
          {btnClicked && <div className="text-green-600 flex flex-start my-2 text-center"> <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
</svg><p className="text-green-400 text-lg">Item added to cart!</p></div>}
          </form>
        </div>
      </div>
    </div>
  );
}
