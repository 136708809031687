import { useEffect, useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import Breadcrumbs from "../Components/Breadcrumbs";
import { collection, doc, getDocs, query, where } from "firebase/firestore";
import { CardSkeleton } from "../Components/Loading";
import db from "../firebase";
import { ShoppingCartCheckoutOutlined } from "@mui/icons-material";

export default function ProductList() {
  const { slug, categoryName } = useParams();
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getProductsData = async () => {
      let productsRef;
      if (slug === "All") {
        productsRef = collection(db, "Products");
      } else {
        const categoryDocRef = doc(db, "Categories", slug);
        productsRef = query(
          collection(db, "Products"),
          where("productCategory", "==", categoryDocRef)
        );
      }

      setLoading(true);
      try {
        const productsData = await getDocs(productsRef);
        const productsFetched = productsData.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setProducts(productsFetched);
        setLoading(false);
      } catch (err) {
        navigate("/");
        setLoading(false);
      }
    };
    getProductsData();
  }, [slug]);

  if (products.length === 0 && loading)
    return (
      <div className="bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-2xl mx-auto py-16 lg:py-20 lg:max-w-none">
            <Breadcrumbs
              child={categoryName || (slug === "All" ? "Products" : slug)}
            />
            <div className="space-y-4 lg:space-y-0 m-2 lg:grid lg:grid-cols-4 lg:gap-x-4 mt-3">
              <CardSkeleton />
              <CardSkeleton />
              <CardSkeleton />
              <CardSkeleton />
            </div>
          </div>
        </div>
      </div>
    );
  return (
    <div className="bg-white">
      <div className="max-w-2xl mx-auto py-16 px-4 lg:py-20 lg:max-w-7xl lg:px-8">
        <Breadcrumbs
          child={categoryName || (slug === "All" ? "Products" : slug)}
        />
        <div className="mt-6 space-y-12 lg:space-y-0 lg:grid lg:grid-cols-4 lg:gap-x-8">
          {products.length > 0 ? (
            products.map((product) => (
              <div
                className="mx-2 w-68 lg:mb-0 mb-8 border shadow-lg"
                key={product.id}
              >
                <div>
                  <img
                    src={product.productThumbnail_1}
                    alt={product.productName}
                    className="w-full h-60 object-cover"
                  />
                </div>
                <div className="bg-background3">
                  {product.productTag !== 'None' && <div className="flex items-center justify-between px-4 pt-3">
                     <div></div>
                    <div className={`${product.productTag === 'Featured' ? 'bg-featured' : 'New' ? 'bg-indigo-600' : 'bg-red-600'} text-white font-semibold py-1.5 px-4 rounded-full`}>
                      <p className="text-xs">{product.productTag}</p>
                    </div>
                  </div>}
                  <div className="px-4">
                    <div className="flex items-center">
                      <h2 className="text-lg font-semibold">
                        {product.productName}
                      </h2>
                    </div>
                    <div className="flex items-center justify-between pb-3">
                      <h3 className="text-featured text-lg font-semibold">
                        NRs. {product.productPrice}
                      </h3>
                      <div>
                        <Link
                          to={`/product/${product.productName}/${product.id}`}
                          hash={product.id}
                        >
                          <ShoppingCartCheckoutOutlined className="text-primary hover:text-secondary text-lg" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="w-full h-96 lg:h-80 m-0 lg:m-4">
              <div className="border-l-4 border-indigo-500">
                <p className="text-gray-400 text-lg p-2">No Products found!</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
